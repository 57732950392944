import React from "react";
import { Link, graphql } from "gatsby";
import styled from "styled-components";
import { Heading } from "grommet";
import { getTagsIndexPath } from "../tagPathUtils";
import { headingFamily, border } from "../theme";
import PageBanner from "../components/PageBanner/PageBanner";
import FeaturedEvents from "../components/FeaturedEvents/FeaturedEvents";
import PageSection from "../components/PageSection/PageSection";
import { HTMLContent } from "../components/Content";
import NavLink from "../components/Link/NavLink";
import { transformEdgesNodes } from "../components/utils";
import withLayoutAndData from "./generic/withLayoutAndData";

const TagHeading = styled(Heading)`
  ${headingFamily}
`;

const Post = styled.div`
  margin: 10px 0;
  width: 100%;
  ${border({ style: "dashed" })`bottom`}

  a {
    color: inherit;
  }
`;

const MoreSection = styled(PageSection)`
  justify-content: flex-end;
  a {
    font-size: 2rem;
  }
`;

const PostsSection = styled(PageSection)`
  flex-direction: column;
`;

export const TagPage = ({ page, highlightedEvents }) => {
	return (<div>
		<PageBanner title={page.title} image={page.image} />

		<PageSection>
			<TagHeading>
				{page.tag}
			</TagHeading>
		</PageSection>

		<PostsSection>
			{page.posts.map((post) => <Post key={post.slug}><Link to={post.slug}>
				<Heading level={2} size="medium">{post.title}</Heading>
				<HTMLContent content={post.description} />
			</Link></Post>)}
		</PostsSection>

		<MoreSection bottomBorder>
			<NavLink to={getTagsIndexPath(page.tagContentType)} >עוד תגיות...</NavLink>
		</MoreSection>

		<PageSection>
			<FeaturedEvents items={highlightedEvents}
				page={page.slug} />
		</PageSection>
	</div>);
};

export const tagPageQuery = graphql`
  query TagPageQuery($tag: String, $contentType: String) {
    home: markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
      }
      ...RemoteBannerImage
    }
    posts: allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] }, templateKey: {eq: $contentType } }}) {
        totalCount
        edges {
          node {
            fields {
              slug
            }
            frontmatter {
              title
              description
            }
          }
        }
    }
    highlightedEvents: allMarkdownRemark(sort: {order: DESC, fields: [frontmatter___date]},
		    filter: {frontmatter: {templateKey: {eq: "event-page"}, featuredpost: {eq: true}}}, limit: 3) {
      edges {
        ...EventHighlight
      }
    }
  }`;

export default withLayoutAndData(
	({ data, pageContext }) => ({
		page: {
			...data.home.frontmatter,
			image: data.home.image,
			posts: transformEdgesNodes(data.posts),
			tagContentType: pageContext.contentType,
			slug: pageContext.slug,
			tag: pageContext.tag,
		},
		highlightedEvents: data.highlightedEvents,
	})
)(TagPage);
